import { datadogLogs } from '@datadog/browser-logs';

import getEnv from 'helpers/getEnv';

let datadogInitialized = false;

export const initDatadog = () => {
  const env = getEnv();
  const {
    providerId,
    lang = 'en',
    apiVersion = 'v1',
  } = Object.fromEntries(new URLSearchParams(window.location.search));

  if (
    !datadogInitialized &&
    env === 'production' &&
    providerId !== 'mockProviderId'
  ) {
    datadogInitialized = true;
    datadogLogs.init({
      // see https://docs.datadoghq.com/getting_started/site/
      clientToken: 'pub043d733ee0f6877efd86e39c82b692aa',
      site: 'datadoghq.com',
      service: 'guesty-pay-hosted-form',
      env,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: ['error'],
      sessionSampleRate: 100,
    });
  }

  datadogLogs.onReady(() => {
    datadogLogs.setGlobalContext({ providerId, apiVersion, lang });
  });
};

export const reportDataDogAction = (name, eventData = {}) => {
  try {
    if (datadogLogs) {
      datadogLogs.onReady(() => {
        datadogLogs.logger.info(name, eventData);
      });
    }
  } catch (e) {
    console.error('[tokenization] failed to send datadog action:', e);
  }
};
